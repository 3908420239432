.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  color: rgb(218, 15, 15);
  padding: 1vh 5vw; /* Using viewport units for padding */
}

nav ul {
  list-style: none;
  margin: 1vh;
  padding: 0;
  text-align: center;
}


body {
  background-color: #020212ed; 
  overflow-x: hidden;
}


nav li {
  display: inline-block;
  margin-right: 3vw; /* Using viewport units for margin */
  padding-right: 1vw 2vw; /* Using viewport units for padding */
  font-weight: bold;
  font-family: "mario", sans-serif;
}


nav li:last-child {
  margin-right: 0;
}

nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-in-out; 
}

section {
  background-color: #ffffffed;
  padding: 2vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.2vh; /* Using viewport units for bottom position */
  width: 100%;
  height: 0.2vh; /* Using viewport units for height */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease-in-out;
}
nav a:hover:after {
  transform: scaleX(1);
}

nav a:hover {
  
  color: rgb(234, 178, 24);
}


.about-me-link:active {
  animation: shineGold 0.5s;
}

@keyframes shineGold {
  0% {
    color: gold;
  }
  100% {
    color: inherit;
  }
}

#about-me {
  padding-top: 50px;
  color: red;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding-top: 150px; 
  /* padding-bottom: 180px; */
  color: rgb(255, 255, 255);
  background-image: url("city4.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh; 
  
}

.textIntro {
  font-size: 100px;
  text-align: center;
  padding-left: 20px;
  margin-bottom: 33px;
  padding-bottom: 0px;
  animation: colorChange 6s infinite;
  font-family: "SF", sans-serif;
  }

.textIntro span {
  color: #ab47bc; 
}

.textIntro:hover span {
  color: #8e24aa; 
}
@keyframes colorChange {
  0% {
    color: #6C5B7B; 
  }
  25% {
    color: #9A8BCC;
  }
  50% {
    color: #55CBCD;
  }
  75% {
    color: #5E7A7F;
  }
  100% {
    color: #6C5B7B;
  }
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #5858d5;
  color: #000000;
  transition: background-color 0.3s ease-in-out;
}

.social-icons a:hover {
  background-color: #00b3ff;
  /* FBD000 */
  color: #90604f;
  animation: jump 0.3s ease-in-out;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 0px;
  padding-bottom: 20px;
  position: relative;
}


@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.social-icons a:last-child {
  margin-right: 0;
}



@font-face {
  font-family: 'mario';
  src: url('fonts/mario3.ttf')
}
@font-face {
  font-family: 'SF';
  src: url('fonts/SF\ Hollywood\ Hills.ttf')
}



.header nav ul li {
  font-family: 'mario', Arial, sans-serif; /* Custom font for the header */
}

.about-me {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #cac2c2;
  margin-top: 20px;
  border-radius: 10px;
}

.about-me h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.about-me p {
  font-size: 16px;
  text-align: center;
  padding: 0 20px;
}


/* Media query for screens with a maximum width of 768px */
@media (min-width: 992px) {
  .header {
    padding: 30px;
    font-size: 24px;
  }
}

/* Media query for laptops */
@media (max-width: 1440px) {
  .header {
    padding: 25px;
    font-size: 20px;
  }
}

.my-box {
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2";
  color: #ffffff;
  background: #3d395a;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: inset 0px 20px 20px -20px rgba(0, 0, 0, 0.8);
}

.section-content {
  padding: 20px;
  border-radius: 10px;
  width: calc(100vw - 40px); 
  min-height: 100vh;
  margin: 0 auto;
  font-family: 'spartan'
}

.section-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrapper {
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center;
}

.text-wrapper {
  flex: 1;
  margin-left: 6vmax;
  font-size: large;
  align-items: center;
  
}
#about {
  font-size: 20px;
}

.image-wrapper img {
  display: block; 
  border: 3px solid rgb(255, 255, 255); 
  border-radius: 10px;
  margin-right: 10vmin; 
  margin-left: 130px; 
  width: 18vmax;

  
}
.inside-text{
  font-size: 60px;
  color: rgb(150, 157, 228);
}

img {
   /* Adjust the image width as needed */
  height: auto; /* Maintain aspect ratio */
}




::-webkit-scrollbar {
  background-color: #3d395a; 
}


::-webkit-scrollbar-thumb {
  background-color:#3d395a;
}


.resume-button {
  padding: 8px 20px;
  background-color: #0a3b73; /* Replace with your desired background color */
  color: #ffffff; /* Replace with your desired text color */
  border: none;
  border-radius: 5px;
  font-size: 18px; /* Adjust the value to your desired font size */
  cursor: pointer;
  font-family: 'spartan'
}


@font-face {
  font-family: 'spartan';
  src: url('fonts/spartan.ttf')
}

.resume-button:hover {
  background-color: #00b3ff; /* Replace with your desired hover background color */
}


#about-text {
  font-size: 25px;
}

.exp {
  background-color: #040423ed;
}

.exp-text-wrapper {
  flex: 1;
  margin-left: 6vmax;
  font-size: large;
  align-items: center;
  color: #00b3ff;
}

.role {
  font-size: 2vmax;
  font-weight: bold;
}

.description {
  color: white;
  margin-left: 2vmax;
  margin-right: 5vmax;
  padding-right: 70px;
  font-size: 1.4vmax;
  font-weight: 400;
  
}

/* Add other styles here */

.company-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 0.5vmax;
  font-family: spartan;
  font-weight: bold;
  font-size: 1.2vmax;
}

.company-button.active {
  background-color: rgb(138, 16, 220)
}


.skills-heading {
  color: rgb(138, 16, 220)
}

.description {
  padding-top: 2.4vmax;
}
.role-heading {
  color: rgb(138, 16, 220)
}
.exp-button {
    padding: 8px 20px;
    background-color: #0a3b73; /* Replace with your desired background color */
    color: #ffffff; /* Replace with your desired text color */
    border: none;
    border-radius: 5px;
    font-size: 18px; /* Adjust the value to your desired font size */
    cursor: pointer;
    font-family: 'spartan';
    
    margin-left: 1.4vmax;
}

.exp-button:hover {
  background:#3d395a;
}


.proj {
  background-color:#4a808a;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the button horizontally */
  justify-content: center; /* Centers the button vertically */
  min-height: 100vh; /* Makes the section take up the full height of the viewport */
  padding: 20px;

}

.proj-heading {
  text-align: center;
  margin-bottom: 20px; /* Add spacing below the "Projects" heading */
  font-size: 25px;
  color:#000000;

}

.proj-content {
  padding: 20px;
  border-radius: 10px;
  width: calc(100vw - 40px); 
  min-height: 100vh;
  margin: 0 auto;
  font-family: 'spartan';
}

.project-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


.project-box {
  width: calc(23.33% - 20px); 
  padding: 10px;
  border-radius: 10px;
  border: 2px solid rgb(0, 0, 0); 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin: 10px;
}

@media (max-width: 768px) {
  .project-box {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .project-box {
    flex: 1 1 100%; 
  }
}



#proj-name {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

#code {
  text-align: center;
}
.external-link {
  color: black;
 
}

.backend {
  color: #3498db; /* Any color for backend, example: blue */
}

.frontend {
  color: gold; /* Gold for frontend */
}

.skills-heading {
  font-weight: bold;
  font-size: 1.2rem;
}
.scroll-button {
  padding: 10px 20px;
  background-color: #0a3b73; /* Button background color */
  color: white; /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.scroll-button:hover {
  background-color: #00b3ff; /* Hover color */
}

.scroll-button:active {
  transform: scale(0.98); /* Add a slight 'click' effect */
}
